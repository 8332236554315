<template>
    <div class="header_desktop d-none d-sm-block">
        <div class="top-bar d-flex">
            <v-select 
                :menu-props="{ theme: 'light' }" 
                class="language-selector" 
                v-model="$i18n.locale" 
                item-value="param" 
                :item-title="(item) => `${item.item} (${item.title})`"
                :items="LayoutLanguages" 
                variant="underlined"
                @update:model-value="handleSelectLocale"
            >
                <template v-slot:selection="{ item }">
                    <span>{{ item.raw.title }}</span>
                </template>
            </v-select>
        </div>
        <div class="second-layer">
            <crvCooperationLogo @click="goBackHome" class="crv-cooperation-logo ml-12"/>
        </div>
    </div>

    <div class="header_mobile d-flex d-sm-none">
        <div class="top-bar">
            <v-icon icon="$chevronLeft" class="ml-3" @click="goBackToSearch"/>
            <crvCooperationLogo class="crv-cooperation-logo pa-2" @click="goBackToSearch"/>
            <v-select 
                :menu-props="{ theme: 'light' }" 
                class="language-selector" 
                v-model="$i18n.locale" 
                item-value="param" 
                :item-title="(item) => `${item.item} (${item.title})`"
                :items="LayoutLanguages" 
                variant="underlined"
                @update:model-value="handleSelectLocale"
            >
                <template v-slot:selection="{ item }">
                    <span>{{ item.raw.title }}</span>
                </template>
            </v-select>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LayoutLanguages } from "../i18n/config/locales";
import { defaultLocale } from "../i18n/config";
import crvCooperationLogo from '../assets/logo/crvCooperationLogo.vue';
import { mapGetters, mapMutations } from "vuex";
import { useRouter } from 'vue-router';


export default defineComponent({
    name: "SireHeader",
    components: { 
        crvCooperationLogo,
    },
    data: () => ({
        LayoutLanguages, 
        defaultLocale,
    }),
    setup() {
        const router = useRouter();

        const goBackToSearch = () => router.back();
        const goBackHome = () => router.push({ name: 'search' });

        return { 
            router,
            goBackToSearch,
            goBackHome
         }
    },
    methods: {
        ...mapMutations({
            setBullDetail: "setBullDetail",
            setLayout: "setLayout",
            setPreviousLayout: "setPreviousLayout",
        }),
        goToTranslationView() {
            this.setPreviousLayout(this.getLayout)
            this.setLayout('translation-view')
        },
        goToOtherView() {
            this.setLayout(this.getPreviousLayout)
        },
        handleSelectLocale(locale: string) {
            this.$router.replace({ params: { locale } });
        },
    },
    computed: {
        ...mapGetters({
            getLayout: "getLayout",
            getSearchText: "getSearchText",
            getPreviousLayout: "getPreviousLayout",
        }),
    },
});
</script>

<style lang="scss" scoped>
@import "./node_modules/@crv/vue-3-components/dist/assets/variables.scss";

.header_desktop {
    .top-bar {
        background-color: $base-brand-blue;
        height: 44px;
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;

        .language-selector {
            max-width: 55px;
            margin-top: 6px;
            margin-right: 10px;
            color: $base-white;
        }
    }

    .second-layer {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        height: 90px;
        max-height: 90px;
        border-bottom: 1px solid $base-gray-40;

        .crv-cooperation-logo {
            width: 150px;
            height: 60px;
            cursor: pointer;
        }
    }
}

.header_mobile {
    .top-bar {
        background-color: $base-white;
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $base-gray-40;

        .crv-cooperation-logo {
            width: 150px;
            height: 60px;
            cursor: pointer;
        }

        .language-selector {
            max-width: 55px;
            margin-top: 6px;
            margin-right: 10px;
            color: black;
        }
    }
}
</style>
