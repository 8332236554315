<style lang="scss" scoped>
@import '../scss/pedigree.scss';
</style>

<template>
    <div class="pedigree">
        <div class="pedigree__container">
            <div v-if="windowWidth < Device.MOBILEMAX" class="pedigree__switch">
                <div>
                    <v-btn v-if="getPedigreeState > 0" @click="onClickPreviousPedigree" variant="text" color="#2f679c"
                        type="link"><span class="button-content">
                            <v-icon
                                icon="$chevronLeft"
                                color="#7F746F"
                            />
                        </span>{{ getLeftSwitchText() }}
                    </v-btn>
                </div>
                <div>
                    <v-btn v-if="getPedigreeState < 3" @click.prevent="onClickNextPedigree" variant="text" color="#2f679c"
                        type="link"><span class="button-content">
                        </span>{{ getRightSwitchText() }}
                        <v-icon
                            icon="$chevronRight"
                            color="#7F746F"
                        />
                    </v-btn>
                </div>
            </div>
            <div v-if="(getPedigreeState === 0 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card">
                <div class="parentBull__root">
                    <PedigreeCardView 
                        :pedigree-bull="getPedigreeBull" 
                        :is-sire="true" 
                        type="child"
                    />
                </div>
            </div>

            <div v-if="(getPedigreeState === 1 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card parentBull">
                <div class="parentBull__sire container">
                    <PedigreeCardView 
                        :pedigree-bull="pedigreeBullRelation('SIRE')"
                        :is-sire="true"
                        type="parents"
                        type-parents="SIRE"
                        @clicked="onClickSire"
                    />
                </div>
                <div class="parentBull__cow container">
                    <PedigreeCardView 
                        :pedigree-bull="pedigreeBullRelation('DAM')"
                        type="parents"
                        type-parents="DAM"
                        @clicked="onClickSire" 
                    />
                </div>
            </div>
            <div v-if="(getPedigreeState === 2 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card">
                <div class="parentBull">
                    <div class="parentBull__sire grandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_SIRE')"
                            :is-sire="true"
                            type="parents"
                            type-parents="SS"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow grandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_DAM')"
                            type="parents"
                            type-parents="SD" 
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire grandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_SIRE')"
                            :is-sire="true" 
                            type="parents"
                            type-parents="DS"
                            @clicked="onClickSire"
                        /> 
                            <!-- TODO fathers only are clickable apparently, shouldt this be visible somehow? -->
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow grandparentsBull">
                        <PedigreeCardView
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_DAM')"
                            type="parents"
                            type-parents="DD"
                        />
                    </div>
                </div>
            </div>
            <div v-if="(getPedigreeState === 3 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card">
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_SIRES_SIRE')"
                            :is-sire="true" 
                            type-parents="SSS"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_SIRES_DAM')"
                            type-parents="SSD"
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_DAMS_SIRE')"
                            :is-sire="true" 
                            type-parents="SDS"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('PATERNAL_GRAND_DAMS_DAM')"
                            type-parents="SDD"
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_SIRES_SIRE')"
                            :is-sire="true"
                            type-parents="DSS"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_SIRES_DAM')"
                            type-parents="DSD"
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_DAMS_SIRE')"
                            :is-sire="true"
                            type-parents="DDS"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="pedigreeBullRelation('MATERNAL_GRAND_DAMS_DAM')"
                            type-parents="DDD"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import PedigreeCardView from '../components/PedigreeCardView.vue'
import { BullService } from "../services/BullService"
import { BullInfo } from "@/models/bullinfo"
import { Device } from '../models/enums'

const bullService = new BullService();

export default defineComponent({
    name: 'PedigreeView',
    components: {
        PedigreeCardView,
    },
    data() {
        return {
            windowWidth: 0,
            bullInfo: {} as BullInfo,
        };
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },

    watch: {
        '$route.params': {
            handler(newParams) {
                const referenceBase = newParams.base || this.getPedigreeBull?.referenceBase;
                const calculationType = newParams.calculationType || this.getPedigreeBull?.calculationType;
                this.setBaseColor(referenceBase);
                this.setCalcType(calculationType);
            },
            immediate: true,
        }
    },

    computed: {
        ...mapGetters({
            getPedigreeState: 'getPedigreeState',
            getPedigreeBull: "getPedigreeBull",
            bullDetail: "getBullDetail",
            getCalcType: "getCalcType",
            getBaseColor: "getBaseColor",
        }),
    },

    methods: {
        ...mapMutations({
            setPedigreeState: "setPedigreeState",
            setPedigreeBull: "setPedigreeBull",
            setLayout: "setLayout",
            setCalcType: "setCalcType",
            setBaseColor: "setBaseColor",
        }),

        async onClickSire(value: string) {
            const interbullNumber = value;
            let sireInfo: any
            try {
                sireInfo = await bullService.getSire(interbullNumber);
            }
            catch (error) {
                console.log('No sireInfo found.')
            } finally {
                if (sireInfo && Array.isArray(sireInfo) && sireInfo.length > 0) {
                    // first sire found based on interbullNumber
                    const referenceBase = sireInfo[0]?.referenceBase;
                    const calculationType = sireInfo[0]?.calculationType;
              
                    // this.setPedigreeBull(sireInfo[0])
                    this.setPedigreeState(1)

                    this.setBaseColor(referenceBase)
                    this.setCalcType(calculationType)

                    this.$router.push({
                        name: 'bull-detail',
                        params: {
                            ...this.$route.params,
                            interbullNumber: interbullNumber,
                            base: referenceBase,
                            calculationType: calculationType,
                            tabParam: 'Pedigree'
                        },
                    });
                }
            }
        },

        onClickNextPedigree() {
            this.setPedigreeState(this.getPedigreeState + 1)
        },

        onClickPreviousPedigree() {
            this.setPedigreeState(this.getPedigreeState - 1)
        },

        handleResize() {
            this.windowWidth = window.innerWidth;
        },

        getLeftSwitchText(): string {
            switch (this.getPedigreeState) {
                case 0:
                    return this.$t('pedigree.first-generation')
                case 1:
                    return this.$t('pedigree.first-generation')
                case 2:
                    return this.$t('pedigree.second-generation')
                case 3:
                    return this.$t('pedigree.third-generation')
                default:
                    return this.$t('pedigree.first-generation')
            }
        },

        getRightSwitchText(): string {
            switch (this.getPedigreeState) {
                case 0:
                    return this.$t('pedigree.second-generation')
                case 1:
                    return this.$t('pedigree.third-generation')
                case 2:
                    return this.$t('pedigree.fourth-generation')
                case 3:
                    return this.$t('pedigree.fourth-generation')
                default:
                    return this.$t('pedigree.third-generation')
            }
        },

        pedigreeBullRelation(relation: string) {
            return this.getPedigreeBull?.pedigree.find((item) => item.relation === relation )?.identification || {}
        },
    },

    setup() {
        return {
            Device,
        }
    },

    async mounted() {
        try {
            const pedigree: BullInfo = await bullService.getForPedigreeView(this.bullDetail.primaryKey, this.getBaseColor, this.getCalcType)
            this.setPedigreeBull(pedigree)
        }
        catch (error) {
            return
        }
    },

    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
});
</script>
