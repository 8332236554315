<template>
    <div class="detail">
        <v-alert v-if="noPedigreeFound" icon="$alert" class="mb-6" type="error" variant="tonal" :title="$t('details.pedigree-warning-alert-title')" closable></v-alert>
        <div class="detail__container mb-3">
            <div class="detail__header d-flex align-center">
                <div class="d-flex">
                    <v-icon icon="$chevronLeft" class="mr-4 d-none d-sm-block" @click="goBackToSearch"/>
                </div>
                <div v-if="bullDetail" class="bull-name-container">
                    <h2 v-if="bullDetail" class="bull-name">{{ formatEmptyToDash(bullDetail.shortName ? bullDetail.shortName : bullDetail.fullName) }}</h2>
                    <span class="bull-subname">{{ bullDetail.fullName ? bullDetail.fullName : '-' }} | {{
                    bullDetail.artificialInseminationNumber }} | {{ bullDetail.interbullNumber }} </span>
                </div>
            </div>
            <div class="detail__select">
                <v-select 
                    :items="getHairColorOptions" 
                    density="compact" 
                    :label="$t('details.basic-calc')" 
                    bg-color="white"
                    v-model="getHairColorValue" 
                    item-title="optionText" 
                    item-value="id" 
                    return-object variant="underlined" 
                    color="primary"
                    @update:model-value="handleSelectBase"
                ></v-select>
            </div>
        </div>
        <div class="detail__tab-container">
            <div style="display: flex; align-items: center; justify-content: space-between;">
                <v-tabs v-model="tab" color="primary">
                    <v-tab @click="setGeneralView()" :color="baseBlue" value="General">{{ $t('details.header-overview') }}</v-tab>
                    <v-tab @click="setPedigreeView()" :color="baseBlue" value="Pedigree">{{ $t('details.header-origin') }}</v-tab>
                    <v-tab  @click="setBreedingValue()" :color="baseBlue" value="BreedingValues">{{ $t('details.header-breeding') }}</v-tab>
                    <v-tab @click="setGenomicsView()" :color="baseBlue" value="Genomics">{{ $t('details.header-genomics') }}</v-tab>
                    <v-tab @click="setDocumentationView" :color="baseBlue" value="Documentation">{{ $t('header.tab-docs') }}</v-tab>
                </v-tabs>
                <v-btn 
                    class="px-2 float-right" 
                    variant="text" 
                    color="primary"
                    prepend-icon="$printer"
                    @click="generatePdf"
                    >
                    {{ $t("details.print") }}
                </v-btn>
            </div>
            <v-window v-model="tab">
                <v-window-item value="General">
                    <template v-if="!loading">
                        <GeneralView ref="generalView" :reference-base="base"/>
                    </template>
                    <div v-else>
                        <v-progress-circular indeterminate color="#154775" model-value="20"></v-progress-circular>
                    </div>
                </v-window-item>
                <v-window-item value="Pedigree">
                      <PedigreeView />
                </v-window-item>
                <v-window-item value="BreedingValues">
                    <template v-if="!loading">
                        <BreedingValuesView />
                    </template>
                    <div v-else>
                        <v-progress-circular indeterminate color="#154775" model-value="20"></v-progress-circular>
                    </div>
                </v-window-item>
                <v-window-item value="Genomics">
                    <template v-if="!loading">
                    <GenomicsView :hasMarkerEffects="hasMarkerEffects"/>
                    </template>
                    <div v-else>
                        <v-progress-circular indeterminate color="#154775" model-value="20"></v-progress-circular>
                    </div>
                </v-window-item>
                <v-window-item value="Documentation">
                    <DocumentationFormulasView />
                </v-window-item>
            </v-window>
        </div>
        <div class="detail__collapse-container">
            <v-expansion-panels variant="accordion" multiple>
                <v-expansion-panel >
                    <v-expansion-panel-title @click="setGeneralView()">{{ $t('details.header-overview') }}</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <GeneralView :reference-base="base" />
                    </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-title @click="setPedigreeView()">{{ $t('details.header-origin') }}</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <PedigreeView />
                    </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel >
                    <v-expansion-panel-title  @click="setBreedingValue()">{{ $t('details.header-breeding') }}</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <BreedingValuesView />
                    </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel >
                    <v-expansion-panel-title @click="setGenomicsView()">{{ $t('details.header-genomics') }}</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <GenomicsView :hasMarkerEffects="hasMarkerEffects" />
                    </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-title @click="setDocumentationView()">{{ $t('header.tab-docs') }}</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <DocumentationFormulasView />
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
  <SireFooter />
</template>

<script lang="ts">
import { defineComponent } from "vue"
import GeneralView from './GeneralView.vue'
import GenomicsView from './GenomicsView.vue'
import DocumentationFormulasView from './DocumentationFormulasView.vue'
import { Device, Colors } from '../models/enums'
import PedigreeView from './PedigreeView.vue'
import BreedingValuesView from './BreedingValuesView.vue'
import { mapGetters, mapMutations, useStore } from "vuex"
import { BullService } from "../services/BullService"
import { BullInfo } from "@/models/bullinfo"
import { formatValueOrDash } from "@/scripts/format-value-or-dash"
import { createWithEmptyValues } from "@/scripts/init-bullinfo-base"
import SireFooter from '../components/SireFooter.vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import { createPrimaryKey } from "@/scripts/create-primary-key"

const bullService = new BullService();

export default defineComponent({
    name: "BullDetailView",
    components: {
        GeneralView,
        PedigreeView,
        BreedingValuesView,
        GenomicsView,
        DocumentationFormulasView,
        SireFooter
    },

    props: {
        locale: {
            type: String,
            note: 'filled via router'
        },
        interbullNumber: {
            type: String,
            note: 'filled via router',
            required: true,
        },
        base: {
            type: String,
            note: 'filled via router',
            default: 'Zwartbont',
        },
        calculationType: {
            type: String,
            note: 'filled via router',
            default: 'GENOMIC_BREEDING_VALUE',
        },
        tabParam: {
            type: String,
            default: 'General',
            note: 'filled via router'
        },
        searchTerm: {
            type: String,
            note: 'filled via router'
        }
    },

    data() {
        return {
            baseBlue: Colors.BASEBLUE,
            tab: '', 
            windowWidth: 0,
            componentKey: 0,
            loading: true,
            hasMarkerEffects: false,
            noPedigreeFound: false,
        }
    },
    created() {
        this.resetScrollToTop();
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        if (this.interbullNumber && this.primaryKey) {
            this.searchBullsByITB(this.primaryKey).then(() => {
                // Only call setView after bullDetail is populated
                if (this.tabParam) {
                    this.tab = this.tabParam
                    switch (this.tab) {
                        case 'General':
                            this.setGeneralView()
                            break;
                        case 'Pedigree':
                            this.setPedigreeView()
                            break;
                        case 'BreedingValues':
                            this.setBreedingValue()
                            break;
                        case 'Genomics':
                            this.setGenomicsView()
                            break;
                        case 'Documentation':
                            this.setDocumentationView()
                            break;
                    }
                }
            });
        }
    },

    setup() {
        const store = useStore()
        const router = useRouter()
        
        const { mdAndUp } = useDisplay()
        const goBackToSearch = () => router.back();

        return { 
            Device,
            store,
            router,
            goBackToSearch,
            mdAndUp
         }
    },
    computed: {
        ...mapGetters({
            bullDetail: "getBullDetail",
            getCalcType: "getCalcType",
            getBaseColor: "getBaseColor",
        }),
        primaryKey(): string {
            return createPrimaryKey(this.interbullNumber, this.base, this.calculationType)
        },
        getHairColorValue: {
            get(): { optionText: string, id: string } {
                return { optionText: this.hairColorText(this.getBaseColor), id: this.getBaseColor }
            },
            async set(value) {
                this.loading = true
                const calcType = this.getCalcType;
                const baseColor = value.id;
                const interbullNumber = this.bullDetail.interbullNumber
                this.setCalcType(calcType);
                this.$router.replace({ params: { calculationType: calcType } });
                this.setBaseColor(baseColor);
                try {
                    const result = await bullService.getForDetailView(createPrimaryKey(interbullNumber, baseColor, calcType));
                    this.setBullDetail(result);
                this.loading = false
                }
                catch (error) {
                    this.setBreedingValueBase(createWithEmptyValues());
                this.loading = false

                }
            }
        },
        getHairColorOptions(): any {
            return [
                {
                    optionText: this.$t('details.haircolor.black-fur'),
                    id: 'Zwartbont'
                },
                {
                    optionText: this.$t('details.haircolor.red-fur'),
                    id: 'Roodbont'
                },
                {
                    optionText: this.$t('details.haircolor.double-purpose'),
                    id: 'Dubbeldoel'
                },
                {
                    optionText: this.$t('details.haircolor.belgian-blue'),
                    id: 'Belgischblauw'
                }
            ]
        }
    },
    
    watch: {
        interbullNumber: {
            async handler(newValue) {
                if (newValue) {
                    this.noPedigreeFound = false                        
                    const response = await this.searchBullsByITB(this.primaryKey)
                    if (response) {
                        this.setPedigreeView()
                        const pedigree: BullInfo = await bullService.getForPedigreeView(this.bullDetail.primaryKey, this.getBaseColor, this.getCalcType)
                        if (pedigree) {
                            this.setPedigreeBull(pedigree)
                        } else {
                            this.noPedigreeFound = true                
                        }
                        if (this.mdAndUp) { // no scroll to top on mobile
                            this.resetScrollToTop()
                        }
                    } else {
                        this.noPedigreeFound = true
                        if (this.mdAndUp) { // no scroll to top on mobile
                            this.resetScrollToTop()
                        }                 
                    }
                }
            }
        },
    },

    methods: {
        ...mapMutations({
            setBullDetail: "setBullDetail",
            setPedigreeBull: "setPedigreeBull",
            setLayout: "setLayout",
            setCalcType: "setCalcType",
            setBaseColor: "setBaseColor",
            setBreedingValueBase: "setBreedingValueBase"
        }),
        resetScrollToTop(): void {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        async setGeneralView() {
            this.loading = true;

            const calcType = 'GENOMIC_BREEDING_VALUE';
            const baseColor = this.getBaseColor;
            const interbullNumber = this.bullDetail.interbullNumber
            this.setCalcType(calcType);
            this.setBaseColor(baseColor);

            this.$router.push({
                params: {
                    tabParam: 'General',
                    calculationType: calcType 
                },
            })

            try {
                const result = await bullService.getForDetailView(createPrimaryKey(interbullNumber, baseColor, calcType))
                this.setBullDetail(result);
            }
            catch (error) {
                this.setBreedingValueBase(createWithEmptyValues());
            }
            this.loading = false;
        },

        async setPedigreeView() {
            this.$router.replace({
                params: {
                    tabParam: 'Pedigree'
                },
            })
            this.searchBullsByITB(this.primaryKey)
        },

        async setBreedingValue() {
            this.loading = true;

            const calcType = 'GENOMIC_BREEDING_VALUE';
            const baseColor = this.getBaseColor;
            const interbullNumber = this.bullDetail.interbullNumber
            this.setCalcType(calcType);

            this.$router.replace({
                params: {
                    tabParam: 'BreedingValues',
                    calculationType: calcType,
                },
            })

            this.setBaseColor(baseColor);
            try {
                const result = await bullService.getForDetailView(createPrimaryKey(interbullNumber, baseColor, calcType));
                this.setBullDetail(result);

            }
            catch (error) {
                this.setBreedingValueBase(createWithEmptyValues());

            }
            this.loading = false;
        },
        async setGenomicsView() {
            this.loading = true;

            const calcType = 'MARKER_EFFECT';
            const baseColor = this.getBaseColor;
            const interbullNumber = this.bullDetail.interbullNumber
            this.setCalcType(calcType);

            this.$router.replace({
                params: {
                    tabParam: 'Genomics',
                    calculationType: calcType,
                },
            })

            this.setBaseColor(baseColor);
            try {
                const result = await bullService.getForDetailView(createPrimaryKey(interbullNumber, baseColor, calcType))
                this.setBullDetail(result);
                this.hasMarkerEffects = true
            }
            catch (error) {
                // TODO: function below breaks everyting, remove everywhere?
                // this.setBreedingValueBase(createWithEmptyValues());
                console.log('No Marker-effects for Genomics-view:', error)
                this.hasMarkerEffects = false
            }
            this.loading = false;
        },
        setDocumentationView() {
            this.$router.replace({
                params: {
                    tabParam: 'Documentation'
                },
            })
            this.tab = 'Documentation'
        },

        formatEmptyToDash(value: number | string | undefined): number | string | undefined {
            return formatValueOrDash(value);
        },

        hairColorText(val: string) {
            switch (val) {
                case 'Belgisch blauw':
                case 'Belgischblauw':
                    return this.$t('details.haircolor.belgian-blue')
                case 'Dubbeldoel':
                    return this.$t('details.haircolor.double-purpose')
                case 'Roodbont':
                    return this.$t('details.haircolor.red-fur')
                case 'Zwartbont':
                    return this.$t('details.haircolor.black-fur')
                default:
                    return this.$t('details.haircolor.black-fur')
            }
        },
        async searchBullsByITB(value) {
            // TODO: this currently searches via the primaryKey, should this be changed to interbullNumber? or be names differently?
            // Currently breaks when navigating to Genomics view, while not having marker-effects and reloading the page (or hard copy url)
            try {
                const result: BullInfo = await bullService.getForDetailView(value)
                this.setBullDetail(result);
                if (result) {
                    this.setLayout('bull-detail-view')
                    if (this.mdAndUp) { // no scroll to top on mobile
                            this.resetScrollToTop()
                        }   
                    this.loading = false
                    return result
                }
            } catch (error) {
                console.error('Error while searching for bull by ITB', error)
                return
            }
        },
        
        handleResize() {
            this.windowWidth = window.innerWidth;
        },

        handleSelectBase(value: any) {
            this.$router.replace({ params: { base: value.id } });
        },

        generatePdf() {
        (this.$refs.generalView as any).generatePdf()
        },
    },
    mounted() {
        this.setBaseColor(this.base);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
});
</script>

<style lang="scss" scoped>
// TODO: remove this file if possible
@import "../scss/detail.scss";

.detail__header {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .detail__header {
    margin-bottom: 1rem;
  }
}

.bull-name-container{
    align-items: center;

    .bull-name {
        font-weight: 600;
    }

    .bull-subname {
        font-size: $font-size-xs;
        color: $base-gray-90;
    }

}
</style>