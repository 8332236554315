<template>
  <SireHeader />
  <MaintenanceView v-if="maintenanceView"/>
  <router-view v-else></router-view>
</template>

<style lang="scss" src="./scss/_global.scss"></style>

<script lang="ts">
import { Locales } from '@/i18n/config/locales';
import { defaultLocale } from "./i18n/config";
import SireHeader from './components/SireHeader.vue';
import { defineComponent } from "vue";
import MaintenanceView from "./components/MaintenanceView.vue";
import {BullService} from "@/services/BullService";


export default defineComponent({
  name: "App",
  components: { 
    SireHeader,
    MaintenanceView
  },
  data: () => ({ 
    Locales, 
    defaultLocale,
    maintenanceView: false
    
  }),

  computed: {
    getCookiebotLanguage() {
      const locale = this.$router.currentRoute.value.params.locale
      if (locale === 'nl_nl') {
        return 'nl'
      } else if (locale === 'en_us') {
        return 'en'
      } else {
        return this.defaultLocale
      }
    }
  },

  methods:{
    async showMaintenanceView(){
          if (await new BullService().isUnderMaintenance()) {
            return true
          } else if (process.env.VUE_APP_MAINTENANCE_MODE === 'true') {
            console.log('Maintenance mode is enabled')
            return true
          } else {
            return false
          }
    },

    shoutUsedSearchIndex(){
      if (process.env.VUE_APP_SEARCH_SHOUT_ENDPOINT) {
        if (process.env.VUE_APP_SEARCH_API_ENDPOINT.includes("-tst-")) {
          console.warn('Develop uses TST search-index:', process.env.VUE_APP_SEARCH_API_ENDPOINT)
        } else if (process.env.VUE_APP_SEARCH_API_ENDPOINT.includes("-acc-")) {
          console.warn('Develop uses ACC search-index:', process.env.VUE_APP_SEARCH_API_ENDPOINT)
        } 
      }
    },

    loadCookiebot(language: string) {
      // On first load, defaultLocale is set, when route.params.lang is populated, it will be used instead
      const existingScript = document.getElementById('Cookiebot')
      if (existingScript) {
        existingScript.remove()
      }

      const script = document.createElement('script')
      script.id = 'Cookiebot'
      script.src = 'https://consent.cookiebot.com/uc.js'
      script.setAttribute('data-cbid', 'f057886a-5024-481b-85df-78d685c861e7')
      script.setAttribute('data-blockingmode', 'auto')
      script.setAttribute('data-culture', language)
      script.type = 'text/javascript'

      document.head.appendChild(script)
    },
  },

  mounted() {
        this.showMaintenanceView().then(result => {
          this.maintenanceView = result;
        }),
        this.shoutUsedSearchIndex()

        // Cookiebot-script, only visible in TST, ACC and PROD
        this.loadCookiebot(this.getCookiebotLanguage)

        this.$watch('getCookiebotLanguage', (newVal) => {
          this.loadCookiebot(newVal)
        })
    }
})
</script>


<style lang="scss">
//importing stylesheets does not work yet, with Vite is does?
// @import "@crv/vue-3-components/dist/assets/base.scss";
@import "./node_modules/@crv/vue-3-components/dist/assets/base.scss";
// @import "@crv/vue-3-components/dist/assets/vuetify/crv-vuetify-styling";
@import "./node_modules/@crv/vue-3-components/dist/assets/vuetify/crv-vuetify-styling";

$mobile-font-size: $mobile-font-size-base;
$tablet-font-size: $tablet-font-size-base;
$desktop-font-size: $desktop-font-size-base;
$base-font-size: 16px;

// Base
#sireSearch{
    font-size: $base-font-size;
    font-family: $font-family-base;
    -webkit-font-smoothing: antialiased;

}

/* TODO remove below two styles when styling is enforces from vue-3-components */
// PR is in place: https://dev.azure.com/crv4all/DevOps/_git/crv-monorepo/pullrequest/7728?_a=files
.v-data-table {
  .v-data-table__td {
    font-variant-numeric: tabular-nums; 
  }
}

.v-table .v-table__wrapper {
  overflow-y: hidden;
}

.v-table table thead tr th {
  background-image: linear-gradient(
    rgba(236, 236, 236, 0.4),
    rgba(236, 236, 236, 0.4),
    rgba(236, 236, 236, 0.4)
  ) !important;
  font-weight: $font-weight-strong !important;
}


mark { 
    background-color: transparent;
    font-weight: $font-weight-strong;
}
</style>